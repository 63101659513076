import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

import Video from "../components/video"

import { Box, Hidden, Paper, Typography, Grid, List, ListItem, Container, Button, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { Modal } from 'antd';
import 'antd/dist/antd.css';

import Ourproducts from "../components/ourproducts"

import AfricropVideo from "../images/videonew.webm"

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import styled from "styled-components";

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);



  const Bline = styled.hr`
  width: 70px;
 border-bottom:2px solid #1c6a9c;
 margin-bottom:20px;
 `

 export default function IndexPage()
 {

   const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "homebg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }

      }
    `
  )

  const image = getImage(placeholderImage);
  
  // Use like this:
  const bgImage = convertToBgImage(image);


   const [visible, setVisible] = React.useState(false);
   const [name, setName] = React.useState('');
   const [email, setEmail] = React.useState('');
   const [message, setMessage] = React.useState('');
   const [mobile, setMobile] = React.useState('');
   const [open, setOpen] = React.useState(false);
   const [result, setResult] = React.useState('');
   const [submitname, setSubmitname] = React.useState('Submit');
 
   const handleCancel = e => {
     setVisible(false);
   };
 
   React.useEffect(() => {
     setVisible(true);
 },[]);
 
 const handleChange = (event) =>{
   const target = event.target;
   const value = target.value;
   const name = target.name;
   if(name==="name")
   {
     setName(value);
   }
   if(name==="email")
   {
     setEmail(value);
   }
   
   if(name==="message")
   {
     setMessage(value);
   }
 
   if(name==="mobile")
   {
     setMobile(value);
   }
   
 }
 
 
 const handleClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
    setOpen(false);
 };
 const handleSubmit = (event) => {
   event.preventDefault();
   setSubmitname('Submitting... ')
   let collection={};
     collection.name = name;
     collection.email = email;
     collection.message = message;
     collection.mobile = mobile;
     collection.scode = "sdferderv3876MBg";
 
     fetch('https://apiv2.chennaihost.com/site/contact', {
       method: "post",
       headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
       },
 
       //make sure to serialize your JSON body
       body: JSON.stringify(collection),
       })
       .then( (response) => { 
         return response.json();
       })
       .then((data) => {
         if(data)
         {
           setResult(data.result);
           setName('');
           setEmail('');
           setMessage('');
           setMobile('');
           setOpen(true);
           setSubmitname('submit');
        
         }
      }
       )
 
 
 };
   
   return(
  <Layout>
    <Seo title="Ultrasound - X Ray - CT scan" description="AFRICORP HEALTHCARE SYSTEMS LTD are the authorized distributors for Philips Medical Systems Nederland B.V. in Zambia bringing in world class technologies consulting and solutions, innovative solutions for clients" />
    <ThemeProvider theme={theme}>
    <video autoPlay muted loop width="100%" height="auto">
      <source src={AfricropVideo} type="video/webm" />
    </video>

    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ backgroundAttachment:'fixed' }}
    >
    <Box pt={4} pb={4} bgcolor="rgb(243 243 243 / 69%)">
    <Container>
      <Grid container>
      <Grid item xs={12}>
      <Typography variant="h3" component="h1" align="center">
      Innovative Healthcare Technology
        </Typography>
        <Bline/>
        <Typography gutterBottom paragraph variant="body1">
        AFRICORP HEALTHCARE SYSTEMS LTD are the authorized distributors for Philips Medical Systems Netherlands B.V. in Zambia bringing in world class technologies consulting and solutions, innovative solutions for clients across a wide range of medical equipment such as CT scanners, MRI scanners, Ultrasound, Color Dopplers, C-arms, Mobile & Fixed Xray units, Mammography and CathLab.
        </Typography>
        <Typography gutterBottom paragraph variant="body1">
        AfriCorp Healthcare Systems Ltd are the technology partners and offers a latest innovative healthcare technology as Consulter, System integrator and Solution provider. This puts our clients into the power of making right decisions independently from technology vendors
        </Typography>
        <Box align="center" mt={2}>
          <Link to="/about/">
        <Button variant="contained" className="news-btn" size="large"> <b>Read More</b> </Button>
        </Link>
</Box>

      </Grid>
      </Grid>
      
    </Container>
    </Box>
    </BackgroundImage>


    {/* PRODUCTS  starts here */}
    <Box bgcolor="grey.300" pt={4} pb={4}>

    <Container>
      <Grid container justifyContent="center">
      <Grid item xs={12}>
      <Typography  gutterBottom variant="h3" component="h2" align="center">
        Our Products
        </Typography>
        <Bline/>
        </Grid>
        
        <ScrollAnimation animateIn='zoomIn'> 

       <Ourproducts/>

       </ScrollAnimation>
       </Grid>
       </Container>
       </Box>

      

         {/* video starts */}

         <Box pt={4} pb={5}  bgcolor="grey.100">
           <Container>
             <Grid container justifyContent="center">
             <Grid item xs={12} md={8}>
       <Typography  gutterBottom variant="h3" component="h2" align="center">
        Video
        </Typography>
        <Bline/>
       <Video
        videoSrcURL="https://www.youtube.com/embed/taCGZ9Pq358"
        videoTitle="Official Music Video on YouTube"
      />
       </Grid>
             </Grid>
           </Container>
         </Box>

         {/* Why Healthcare Facilities Choose Us */}
         <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ backgroundAttachment:'fixed' }}
    >
         <Box pt={4} pb={5} bgcolor="rgba(236, 236, 236, 0.68)">
       <Container>
 <Grid container>
 <Grid item xs={12}>
 <Typography  gutterBottom variant="h3" component="h2" align="center">
  Why Healthcare Facilities Choose Us
   </Typography>
   <Bline/>
   </Grid>
   </Grid>
   
 <Box mt={2} p={1}>
 <ScrollAnimation animateIn='zoomIn'>
   <Grid container>
   <Grid item xs={12} sm={6} md={4}>
     <Box p={1} >
   <Paper elevation={3} className="bio">
     <Box p={2}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Bio Medical Engineers</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Quickdiagnostic</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Technical Support</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Confidently service equipment</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Reduce future failures</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase service</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> productivity</Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
     </Grid>

     <Grid item xs={12} sm={6} md={4}>
     <Box p={1}>
   <Paper elevation={3} className="bio">
     <Box p={2}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Clinical Users</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Higher equipment up time Reduce  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  Technical Support  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  interruptions  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  High image quality  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase scanning productivity  </Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
     </Grid>

     <Grid item xs={12} sm={6} md={4}>
     <Box p={1}>
   <Paper elevation={3} className="bio">
     <Box p={2}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Administrators</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase equipment availability</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Higher patient capacity</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Lowering service costs</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Safe and reliable</Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
     </Grid>


 </Grid>
 </ScrollAnimation>
 </Box>

 
</Container>
</Box>
</BackgroundImage>


 {/* News and Events */}
 <Box bgcolor="grey.300" pt={4} pb={5}>
   
   <Container>
     <Grid container>
     <Grid item xs={12}>
     <Typography  gutterBottom variant="h3" component="h2" align="center">
       News and Events
       </Typography>
       <Bline/>
       </Grid>
       </Grid>
       <ScrollAnimation animateIn='zoomIn'>
       <Grid container justify="center">
       <Grid item xs={12} md={4} sm={6} className="news">
         <Box p={1}>
         <Paper>
         <Box p={2}>
         <StaticImage
      src="../images/covid.jpg"
      alt="covid"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
       <Box pt={2} pb={1}>
       <Typography  gutterBottom variant="h6"><b>COVID-19</b></Typography>
       </Box>
       <Typography  gutterBottom variant="body1">
       COVID-19 Response and healthcare solutions
         </Typography>
         <a href="https://www.usa.philips.com/healthcare/medical-specialties/covid-19#!=&triggername=menu_one"  rel="noopener noreferrer" target="_blank" style={{ color:'#000'}}>
         <Box display="flex" pt={2}>
         <Box flexGrow={1} bgcolor="grey.300" className="news2">
         <Box p={2}>
         <Typography  gutterBottom variant="body1">
           Learn More
           </Typography>
           </Box>
           </Box>
           <Box className="news1" p={2} style={{ backgroundColor:'red', color:'#fff' }}>
         <ArrowRightAltIcon style={{ zIndex:1 }}/>
         </Box>
         </Box>
         </a>
       </Box>
       </Paper>
       </Box>
       </Grid>

       {/* <Grid item xs={12} md={4} sm={6} className="news">
         <Box p={1}>
         <Paper>
         <Box p={2}>
         <Sleeprespiratory/>
       <Box pt={2} pb={1}>
       <Typography  gutterBottom variant="h6"><b>Lorem ipsum dolor</b></Typography>
       </Box>
       <Typography  gutterBottom variant="body1">
         Lorem ipsum dolor sit amet conse ctetur adipiscing elit sed do eiusmod tempor ares incididunt utlabore.
         </Typography>
         <Link to="/newsdetails/" style={{ color:'#000'}}>
         <Box display="flex" pt={2}>
         <Box flexGrow={1} bgcolor="grey.300" className="news2">
         <Box p={2}>
         <Typography  gutterBottom variant="body1">
           Learn More
           </Typography>
           </Box>
           </Box>
           <Box className="news1" p={2} style={{ backgroundColor:'red', color:'#fff' }}>
         <ArrowRightAltIcon style={{ zIndex:1 }}/>
         </Box>
         </Box>
         </Link>
       </Box>
       </Paper>
       </Box>
       </Grid>

       <Grid item xs={12} md={4} sm={6} className="news">
         <Box p={1}>
         <Paper>
         <Box p={2}>
         <Radiationoncology/>
       <Box pt={2} pb={1}>
       <Typography  gutterBottom variant="h6"><b>Lorem ipsum dolor</b></Typography>
       </Box>
       <Typography  gutterBottom variant="body1">
         Lorem ipsum dolor sit amet conse ctetur adipiscing elit sed do eiusmod tempor ares incididunt utlabore.
         </Typography>
         <Link to="/newsdetails/" style={{ color:'#000'}}>
         <Box display="flex" pt={2}>
         <Box flexGrow={1} bgcolor="grey.300" className="news2">
         <Box p={2}>
         <Typography  gutterBottom variant="body1">
           Learn More
           </Typography>
           </Box>
           </Box>
           <Box className="news1" p={2} style={{ backgroundColor:'red', color:'#fff' }}>
         <ArrowRightAltIcon style={{ zIndex:1 }}/>
         </Box>
         </Box>
         </Link>
       </Box>
       </Paper>
       </Box>
       </Grid> */}

<Grid item xs={12}>
 <Box pt={2}>
 <Typography align='center'>
   <Link to="/newsevent/">
 <Button variant="contained" className="news-btn" size="large"> <b>View More</b> </Button>
 </Link>
 </Typography>
 </Box>
 <Hidden mdUp>
   <Box mb={4}></Box>
   </Hidden>
</Grid>


     </Grid>
     </ScrollAnimation>
   
   </Container>
   </Box>
    
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}

    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{display: 'flex', alignItems: 'center',}}>
           {result}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon style={{fontSize: 20,}} />
        </IconButton>,
      ]}
     
    />
        </Snackbar>
        <Modal
          title="Contact Us"
          visible={visible}
          onCancel={handleCancel}
          footer={[]}
        >
              <ValidatorForm onSubmit={handleSubmit}  autoComplete="off">
<TextValidator 
          id="name"
          name="name"
          label="Your Name" 
          value={name}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

<TextValidator 
          id="mobile"
          name="mobile"
          label="Mobile" 
          value={mobile}
          onChange={handleChange}
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="email"
          name="email"
          label="Email" 
          value={email}
          onChange={handleChange}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'email is not valid']}
          fullWidth
          margin="normal"
          variant="outlined" />

          <TextValidator 
          id="message"
          name="message"
          label="Message" 
          value={message}
          onChange={handleChange}
          multiline
          rows="4"
          validators={['required']}
          errorMessages={['this field is required']}
          fullWidth
          margin="normal"
          variant="outlined" />
          
           

        <Button variant="contained" color='primary' size="large" type="submit">{submitname}</Button>
         </ValidatorForm>
  
        </Modal>

    </ThemeProvider>
  </Layout>
  
);
  }
